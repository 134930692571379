.App {
  text-align: center;
}

.ant-input, .ant-input-number, .ant-switch {
  margin-top: 5%;
  width: 100%;
}

.ant-button {
  margin-top: 5%;
}

.ant-alert {
  margin-bottom: 1%;
}

.recharts-surface {
  overflow: visible;
}

.custom-tooltip {
  margin: 200;
}